import { useMediumToMediaQuery } from '@madpaws/design-system';
import getConfig from 'next/config';
import React from 'react';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';

import styles from './PromoBanner.module.css';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

type Props = {
  onClick: () => void;
};

export const PromoBanner = ({ onClick }: Props): ReactElement => {
  const isMediumToMediaQuery = useMediumToMediaQuery();
  return (
    <div className={styles.root}>
      {!isMediumToMediaQuery && (
        <ImageWithFallback
          alt="Picture of a pet"
          fallback={`${publicRuntimeConfig.staticPath}/images/DogBalloonBanner.png`}
          height="100%"
          loading="lazy"
          webpSrc={`${publicRuntimeConfig.staticPath}/images/DogBalloonBanner.webp`}
          width={120}
        />
      )}
      <p onClick={onClick}>Fetch $25 OFF your first booking!</p>
      {isMediumToMediaQuery && (
        <ImageWithFallback
          alt="Picture of a pet"
          fallback={`${publicRuntimeConfig.staticPath}/images/DogBalloonBanner.png`}
          height="100%"
          loading="lazy"
          webpSrc={`${publicRuntimeConfig.staticPath}/images/DogBalloonBanner.webp`}
          width={120}
        />
      )}
    </div>
  );
};
