import { Dialog, Heading } from '@madpaws/design-system';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import React, { useCallback, useEffect, useMemo } from 'react';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import { HOME_PAGE_SLUG } from '~/common/constants/pageLayout';
import { useDeviceType } from '~/hooks/useDeviceType';

import styles from './FirstBookingPromoDialog.module.css';
import { PromoBanner } from './PromoBanner/PromoBanner';
import { useAuthDialog } from '../AuthenticationProvider/useAuthDialog';
import { NEW_CUSTOMER_COUPON_EPPO_ID, NEW_CUSTOMER_COUPON_EPPO_ID_VALUES } from '../Eppo/constants';
import { useEppoStringAssignment } from '../Eppo/helpers';
import { useUserLoggedInContext } from '../UserAuthProvider/UserAuthProvider';
import { trackEvent } from '../analytics/analytics';
import {
  PROMO_BANNER_CTA_CLICK_EVENT_NAME,
  PROMO_MODAL_CTA_CLICK_EVENT_NAME,
  PROMO_MODAL_DISMISSED_EVENT_NAME,
  PROMO_MODAL_VIEWED_EVENT_NAME,
} from '../analytics/constants';
import { DOM_CONTAINER_ID } from '../constants';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

const FIRST_BOOKING_PROMO_DIALOG_COOKIE_NAME = 'first_booking_25_promo_dialog_opened';
const PROMO_DIALOG_ID = 'first_booking_25';

const FIRST_BOOKING_PROMO_TC_LINK =
  'https://madpaws.brainfi.sh/articles/25-coupon-offer-for-new-customers-J5hstVHO3S';

export const FirstBookingPromoDialog = (): ReactElement | null => {
  const { isUserLoggedIn } = useUserLoggedInContext();
  const deviceType = useDeviceType();

  const eventParams = useMemo(
    () => ({
      device_type: deviceType,
      page: HOME_PAGE_SLUG,
      type: PROMO_DIALOG_ID,
    }),
    [deviceType]
  );

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const isNewCustomerCouponEnabled =
    useEppoStringAssignment(NEW_CUSTOMER_COUPON_EPPO_ID) === NEW_CUSTOMER_COUPON_EPPO_ID_VALUES.ON;

  const openDialog = useCallback((): void => {
    Cookies.set(FIRST_BOOKING_PROMO_DIALOG_COOKIE_NAME, 'true');
    setIsDialogOpen(true);
  }, []);

  useEffect(() => {
    if (
      !isUserLoggedIn &&
      !Cookies.get(FIRST_BOOKING_PROMO_DIALOG_COOKIE_NAME) &&
      isNewCustomerCouponEnabled
    ) {
      openDialog();
    }
  }, [isUserLoggedIn, openDialog, isNewCustomerCouponEnabled]);

  const { openAuthDialog } = useAuthDialog();

  const closeDialog = (): void => {
    setIsDialogOpen(false);
    trackEvent(PROMO_MODAL_DISMISSED_EVENT_NAME, eventParams);
  };

  const onClickCta = (): void => {
    setIsDialogOpen(false);
    openAuthDialog(
      'Create a free account to get $25 OFF your first booking',
      HOME_PAGE_SLUG,
      PROMO_DIALOG_ID
    );
    trackEvent(PROMO_MODAL_CTA_CLICK_EVENT_NAME, eventParams);
  };

  if (isUserLoggedIn || !isNewCustomerCouponEnabled) {
    return null;
  }

  const onPressBannerCta = (): void => {
    openDialog();
    trackEvent(PROMO_BANNER_CTA_CLICK_EVENT_NAME, eventParams);
  };

  const handleAfterOpenDialog = (): void => {
    trackEvent(PROMO_MODAL_VIEWED_EVENT_NAME, eventParams);
  };

  return (
    <div className={styles.container}>
      <PromoBanner onClick={onPressBannerCta} />
      <Dialog
        cta={{ label: 'Unlock $25 Off – Create an Account!', onClick: onClickCta }}
        domContainerId={DOM_CONTAINER_ID}
        heading="Welcome to the pack!"
        isOpen={isDialogOpen}
        onAfterOpen={handleAfterOpenDialog}
        onRequestClose={closeDialog}
      >
        <div className={styles.root}>
          <ImageWithFallback
            alt="Picture of a pet"
            fallback={`${publicRuntimeConfig.staticPath}/images/DogBalloon.png`}
            loading="lazy"
            webpSrc={`${publicRuntimeConfig.staticPath}/images/DogBalloon.webp`}
          />

          <Heading level={3}>Woof! Save $25 OFF your first booking!</Heading>
          <p>Create your free account and we will email you an exclusive discount code.</p>

          <a
            className={styles.root}
            href={FIRST_BOOKING_PROMO_TC_LINK}
            rel="noreferrer"
            target="_blank"
          >
            T&C&apos;s apply
          </a>
        </div>
      </Dialog>
    </div>
  );
};
