import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { trackEvent } from '~/components/analytics/analytics';
import { VIEWED_HOME_PAGE } from '~/components/analytics/constants';
import { prepareUrl } from '~/components/utils/search';

import { ExploreMoreProducts } from './ExploreMoreProducts/ExploreMoreProducts';
import { ExploreMoreServices } from './ExploreMoreServices/ExploreMoreServices';
import { Hero } from './Hero/Hero';
import { HowDoesMadPawsWork } from './HowDoesMadPawsWork/HowDoesMadPawsWork';
import { PageHead } from './PageHead/PageHead';
import { PetStore } from './PetStore/PetStore';
import { Trust } from './Trust/Trust';
import { FAQS } from './constants';
import { FAQs } from '../../../common/components/FAQs/FAQs';

import type { NextPage } from 'next';
import type { SearchFilters } from '~/common/types/search';

type Props = {
  canonicalUrl: string | null;
  hasValidation: boolean;
  searchFilters: SearchFilters;
};

export const Home: NextPage<Props> = ({ canonicalUrl, searchFilters }) => {
  const router = useRouter();

  useEffect(() => {
    trackEvent(VIEWED_HOME_PAGE);
  }, []);

  const handleFiltersSave = (newSearchFilters: SearchFilters): void => {
    const url = prepareUrl(newSearchFilters);
    router.push(url);
  };

  return (
    <>
      <PageHead canonicalUrl={canonicalUrl} />
      <Hero handleFiltersSave={handleFiltersSave} searchFilters={searchFilters} />
      <ExploreMoreServices />
      <HowDoesMadPawsWork />
      <Trust />
      <FAQs faqs={FAQS} headingColor="defaultColor" />
      <PetStore />
      <ExploreMoreProducts />
    </>
  );
};
