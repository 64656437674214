import { Heading, useLargeFromMediaQuery, useMediumFromMediaQuery } from '@madpaws/design-system';
import classnames from 'classnames';
import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import { DesktopSearchWidgetWrapper } from '~/common/components/SearchBarWidget/DesktopSearchWidgetWrapper/DesktopSearchWidgetWrapper';
import { MobileSearchWidgetWrapper } from '~/common/components/SearchBarWidget/MobileSearchWidgetWrapper/MobileSearchWidgetWrapper';
import {
  NEW_CUSTOMER_COUPON_EPPO_ID,
  NEW_CUSTOMER_COUPON_EPPO_ID_VALUES,
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';
import { FirstBookingPromoDialog } from '~/components/FirstBookingPromoDialog/FirstBookingPromoDialog';

import styles from './Hero.module.css';
import { ProductReview } from './components/ProductReview';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

const { publicRuntimeConfig } = getConfig();

type Props = {
  handleFiltersSave: (searchFilters: SearchFilters) => void;
  searchFilters: SearchFilters;
};

const getSearchExperimentValues = (
  searchExperimentEnabled: boolean
): {
  description: string;
  showProductReview: boolean;
  title: string;
} =>
  searchExperimentEnabled
    ? {
        title: 'Trusted pet care, anytime, anywhere',
        description: `Australia’s #1 pet services – search 50,000+ trusted pet sitters & dog walkers`,
        showProductReview: false,
      }
    : {
        title: 'Press paws on pet stress',
        description: 'Find trusted pet sitters & dog walkers near you',
        showProductReview: true,
      };

export const Hero = ({ searchFilters, handleFiltersSave }: Props): ReactElement => {
  const isLargeFromViewport = useLargeFromMediaQuery();
  const isMediumFromViewport = useMediumFromMediaQuery();

  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;

  const isNewCustomerCouponEnabled =
    useEppoStringAssignment(NEW_CUSTOMER_COUPON_EPPO_ID) === NEW_CUSTOMER_COUPON_EPPO_ID_VALUES.ON;

  const { title, description, showProductReview } = getSearchExperimentValues(
    searchExperimentEnabled && !isLargeFromViewport
  );

  const mobileSearchWrapperClassNames = classnames({
    [styles.mobileSearchWrapper]: !searchExperimentEnabled || isLargeFromViewport,
  });

  return (
    <>
      {isNewCustomerCouponEnabled && <FirstBookingPromoDialog />}
      <div className={styles.root}>
        <div className={styles.titleContainer}>
          <Heading
            alignment="centerAlign"
            fontFamily="heading"
            hasTightLineHeight
            level={2}
            size={isLargeFromViewport ? 'large2x' : isMediumFromViewport ? 'large1x' : 'medium'}
          >
            {title}
          </Heading>
          <Heading alignment="centerAlign" level={1}>
            {description}
          </Heading>
        </div>
        {!isLargeFromViewport && (
          <div className={mobileSearchWrapperClassNames}>
            <MobileSearchWidgetWrapper
              handleFiltersSave={handleFiltersSave}
              searchFilters={searchFilters}
            />
            {showProductReview && <ProductReview />}
          </div>
        )}
        <DesktopSearchWidgetWrapper
          handleFiltersSave={handleFiltersSave}
          searchFilters={searchFilters}
        />
        <div className={styles.imageWrapper}>
          {isLargeFromViewport ? (
            <ImageWithFallback
              alt="Picture of dog walker walking with two dogs"
              fallback={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_desktop.jpg`}
              height={526}
              loading="eager"
              webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_desktop.webp`}
              width={1280}
            />
          ) : (
            <ImageWithFallback
              alt="Picture of dog walker walking with two dogs"
              fallback={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_mobile.jpg`}
              height={290}
              loading="eager"
              webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_mobile.webp`}
              width="100%"
            />
          )}
        </div>
        {isLargeFromViewport && <ProductReview />}
      </div>
    </>
  );
};
