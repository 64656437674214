import { useContext } from 'react';

import { AuthenticationDialogContext } from './AuthenticationProvider';
import {
  SFS_HOME_SEARCH_TEST_EPPO_ID,
  SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES,
} from '../Eppo/constants';
import { useEppoStringAssignment } from '../Eppo/helpers';
import { useUserLoggedInContext } from '../UserAuthProvider/UserAuthProvider';

type FeatureFlagsActions = {
  checkLoginAndOpenAuthDialog: (
    callBack: () => void,
    title: string,
    successRedirectUrl?: string
  ) => void;
  checkLoginAndOpenLoginRequiredDialog: (callBack: () => void, title: string) => void;
  openAuthDialog: (title: string, successRedirectUrl?: string, queryParam?: string) => void;
  openLoginRequiredDialog: (title: string) => void;
};

export const useAuthDialog = (): FeatureFlagsActions => {
  const { openAuthDialog, openLoginRequiredDialog } = useContext(AuthenticationDialogContext);

  const { isUserLoggedIn } = useUserLoggedInContext();

  const searchExperimentEnabled =
    useEppoStringAssignment(SFS_HOME_SEARCH_TEST_EPPO_ID) ===
    SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES.ON;

  // WEB-2247 Disable login modal for now
  // const { treatments, isSplitIoLoading } = useSplitIO();

  // const isFeatureEnabled = !isSplitIoLoading
  //   ? treatments[FEATURE_HOLD_OUT_SPLIT_ID]
  //   === FEATURE_HOLD_OUT_SPLIT_ID_ID_VALUES.ON
  //   : false;
  const isFeatureEnabled = searchExperimentEnabled;

  const checkLoginAndOpenAuthDialog = (
    callBack: () => void,
    title: string,
    successRedirectUrl?: string
  ): void => {
    if (!isUserLoggedIn && isFeatureEnabled) {
      openAuthDialog(title, successRedirectUrl);
      return;
    }

    callBack();
  };

  const checkLoginAndOpenLoginRequiredDialog = (callBack: () => void, title: string): void => {
    if (!isUserLoggedIn) {
      openLoginRequiredDialog(title);
      return;
    }

    callBack();
  };

  return {
    openAuthDialog,
    checkLoginAndOpenAuthDialog,
    openLoginRequiredDialog,
    checkLoginAndOpenLoginRequiredDialog,
  };
};
